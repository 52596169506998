<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="1500px"
    @close="close"
  >
    <el-descriptions
      border
      :column="8"
      direction="vertical"
      style="padding-bottom: 20px"
    >
      <el-descriptions-item
        v-for="item in commonColumns"
        :key="item.val"
        :label="item.label"
        :span="item.span"
      >
        <span v-if="item.label === '更新时间'">
          {{ list[item.val] | dateFormat }}
        </span>
        <span
          v-else-if="
            item.label === '是否支持4G' ||
            item.label === '是否支持自定义语音' ||
            item.label === '是否支持云台'
          "
        >
          {{ list[item.val] | supportFormat }}
        </span>
        <span v-if="item.label === '消息通知Json'" class="msg-json">
          <el-table border :data="list[item.val] ? [list[item.val]] : []">
            <el-table-column align="center" label="APP推送">
              <template #default="scope">
                {{ formatAppPush(scope.row.appPush) }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="推送时间">
              <el-table-column align="center" label="是否全天执行">
                <template #default="scope">
                  {{ formatAllDay(scope.row.pushTime.allDay) }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="是否重复">
                <template #default="scope">
                  {{ repeatFormat(scope.row.pushTime.repeat) }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="开始时间">
                <template #default="scope">
                  {{ timeFormat(scope.row.pushTime.startTime) }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="结束时间">
                <template #default="scope">
                  {{ timeFormat(scope.row.pushTime.endTime) }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="时区"
                prop="pushTime.timeZone"
              />
            </el-table-column>
            <el-table-column align="center" label="接收消息类型">
              <template #default="scope">
                {{ formatDeviceTypes(scope.row.eventTypes.device) }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="是否接收AI消息推送">
              <template #default="scope">
                {{ scope.row.eventTypes.aiCloud ? '推送' : '不推送' }}
              </template>
            </el-table-column>
          </el-table>
        </span>
        <span v-else>
          {{ list[item.val] }}
        </span>
      </el-descriptions-item>
    </el-descriptions>
    <el-table ref="table" border :data="list.uid_push">
      <el-table-column
        v-for="item in tabelColumns"
        :key="item.prop"
        align="center"
        :formatter="item.formatter"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template slot-scope="scope">
          <span v-if="item.label === '添加时间' || item.label === '更新时间'">
            {{ scope.row[item.prop] | dateFormat }}
          </span>
          <span v-else-if="item.label === 'app类型'">
            {{ scope.row[item.prop] | appTypeFormat }}
          </span>
          <span v-else-if="item.label === '推送类型'">
            {{ scope.row[item.prop] | pushTypeFormat }}
          </span>
          <span v-else>
            {{ scope.row[item.prop] }}
          </span>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
  import { formatDate } from '@/utils/formatDate'

  export default {
    name: 'Details',
    filters: {
      dateFormat(val) {
        return formatDate(new Date(val * 1000), 'yyyy-MM-dd')
      },
      appTypeFormat(val) {
        if (val === 1) {
          return 'ios'
        } else if (val === 2) {
          return '安卓'
        }
        return val
      },
      pushTypeFormat(val) {
        if (val === 0) {
          return 'apns'
        } else if (val === 1) {
          return '安卓gcm'
        } else if (val === 2) {
          return '极光'
        } else if (val === 3) {
          return '华为'
        } else if (val === 4) {
          return '小米'
        } else if (val === 5) {
          return 'vivo'
        } else if (val === 6) {
          return 'oppo'
        } else if (val === 7) {
          return '魅族'
        }
        return val
      },
      supportFormat(val) {
        if (val === 0) {
          return '不支持'
        } else if (val === 1) {
          return '支持'
        }
        return val
      },
    },
    data() {
      return {
        list: [],
        title: '详情',
        loading: true,
        dialogVisible: false,
        queryForm: {
          UID: '',
        },
        commonColumns: [
          {
            label: '推送间隔',
            val: 'detect_interval',
          },
          {
            label: '检测类型',
            val: 'ai_type',
          },
          {
            label: '是否支持4G',
            val: 'mobile_4g',
          },
          {
            label: '是否支持自定义语音',
            val: 'is_custom_voice',
          },
          {
            label: '是否支持云台',
            val: 'is_ptz',
          },
          {
            label: '设备规格码',
            val: 'ucode',
          },
          {
            label: '设备时区',
            val: 'tz',
          },
          {
            label: '更新时间',
            val: 'updTime',
          },
          {
            label: '消息通知Json',
            val: 'msg_notify',
          },
        ],
        tabelColumns: [
          {
            label: '用户名',
            prop: 'username',
          },
          {
            label: '用户ID',
            prop: 'userID',
            width: '180',
          },
          {
            label: '设备功能表',
            prop: 'uid_set',
          },
          {
            label: 'appID',
            prop: 'appBundleId',
          },
          {
            label: 'app类型',
            prop: 'app_type',
          },
          {
            label: '推送类型',
            prop: 'push_type',
          },
          {
            label: '设备验证令牌',
            prop: 'token_val',
            width: '130',
          },
          {
            label: '极光推送令牌',
            prop: 'jg_token_val',
          },
          {
            label: '手机唯一标识',
            prop: 'm_code',
            width: '210',
          },
          {
            label: '推送语言类型',
            prop: 'lang',
          },
          {
            label: 'utc时区',
            prop: 'tz',
          },
          {
            label: '添加时间',
            prop: 'addTime',
          },
          {
            label: '更新时间',
            prop: 'updTime',
          },
        ],
        pushTypes: {
          1: '人',
          2: '宠物',
          3: '车',
          4: '包裹',
          51: '移动侦测',
          52: '传感器触发',
          53: '摄像头丢失',
          54: 'PIR',
          55: '门磁',
          56: '外部物体触发',
          57: '人',
          58: '车',
          59: '宠物',
          60: '人脸',
          61: '异响',
          62: '区域闯入',
          63: '区域闯出',
          64: '徘徊检测',
          65: '长时间无人检测',
          66: '往来检测',
          67: '哭声检测',
          68: '手势检测',
          69: '火焰检测',
          70: '遮面检测',
          71: '设备包裹检测',
          72: '电动车检测',
          73: '物理遮挡',
          702: '休眠',
          703: '唤醒',
          704: '低电量',
          606: '语音通话',
          607: '视频通话',
          1022: '有人按门铃',
          1023: '儿童保护',
        },
      }
    },
    methods: {
      showDetails(data) {
        this.dialogVisible = true
        this.list = data
      },
      close() {
        this.dialogVisible = false
        this.list = []
      },
      formatAppPush(val) {
        switch (val) {
          case 0:
            return '只接收消息存表，但不推送'
          case 1:
            return '接收消息并执行APP推送'
          case -1:
            return '关闭推送'
          default:
            return val
        }
      },
      formatAllDay(val) {
        return val === 1 ? '全天APP推送' : '选择时间段进行APP推送'
      },
      formatDeviceTypes(deviceIds) {
        return deviceIds
          .map((id) => this.pushTypes[id] || `未知设备 (${id})`)
          .join(', ')
      },
      repeatFormat(val) {
        //十进制转二进制 1表示执行0表示不执行
        const binaryString = (val >>> 0).toString(2).padStart(8, '0')
        const executionStatus = binaryString.split('').map((bit) => {
          return bit === '1' ? `执行` : `执行`
        })
        return executionStatus.join(', ')
      },
      timeFormat(val) {
        if (val === 0) {
          return 'N/A'
        }
        return formatDate(new Date(val * 1000), 'yyyy-MM-dd hh:mm:ss')
      },
    },
  }
</script>
<style lang="scss" scoped>
  .msg-json ::v-deep .el-table th .cell {
    font-size: 12px;
    color: #909399;
  }
</style>
