var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.visible, width: "800px" },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-width": "120px",
            model: _vm.formData,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: "20" } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "设备规格码", prop: "dCode" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.isDisabled },
                        model: {
                          value: _vm.formData.dCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "dCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.dCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "设备版本", prop: "softwareVer" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.isDisabled },
                        model: {
                          value: _vm.formData.softwareVer,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "softwareVer",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.softwareVer",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "屏幕通道数", prop: "screenChannels" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formData.screenChannels,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "screenChannels",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.screenChannels",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "设备类型", prop: "deviceType" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formData.deviceType,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "deviceType",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.deviceType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分辨率", prop: "resolution" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formData.resolution,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "resolution",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.resolution",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "固件版本", prop: "firmwareVer" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formData.firmwareVer,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "firmwareVer",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.firmwareVer",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "检测类型", prop: "aiType" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formData.aiType,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "aiType",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.aiType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "其它功能", prop: "otherFeatures" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formData.otherFeatures,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "otherFeatures",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.otherFeatures",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "网络类型", prop: "networkType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "选择网络类型",
                          },
                          model: {
                            value: _vm.formData.networkType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "networkType", $$v)
                            },
                            expression: "formData.networkType",
                          },
                        },
                        _vm._l(_vm.networkList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "编码类型", prop: "videoCode" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "选择编码类型",
                          },
                          model: {
                            value: _vm.formData.videoCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "videoCode", $$v)
                            },
                            expression: "formData.videoCode",
                          },
                        },
                        _vm._l(_vm.videoCodeList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Alexa区域", prop: "app_bundle_type" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择Alexa区域",
                          },
                          model: {
                            value: _vm.formData.regionAlexa,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "regionAlexa", $$v)
                            },
                            expression: "formData.regionAlexa",
                          },
                        },
                        _vm._l(_vm.appList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "人形追踪",
                        prop: "supportsHumanTracking",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formData.supportsHumanTracking,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData,
                                "supportsHumanTracking",
                                $$v
                              )
                            },
                            expression: "formData.supportsHumanTracking",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("支持"),
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("不支持"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "自定义语音",
                        prop: "supportsCustomVoice",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formData.supportsCustomVoice,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "supportsCustomVoice", $$v)
                            },
                            expression: "formData.supportsCustomVoice",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("支持"),
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("不支持"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "双频Wifi",
                        prop: "supportsDualBandWifi",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formData.supportsDualBandWifi,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData,
                                "supportsDualBandWifi",
                                $$v
                              )
                            },
                            expression: "formData.supportsDualBandWifi",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("支持"),
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("不支持"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "4G", prop: "supports4g" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formData.supports4g,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "supports4g", $$v)
                            },
                            expression: "formData.supports4g",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("支持"),
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("不支持"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "云台", prop: "supportsPTZ" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formData.supportsPTZ,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "supportsPTZ", $$v)
                            },
                            expression: "formData.supportsPTZ",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("支持"),
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("不支持"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "AI", prop: "supportsAi" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formData.supportsAi,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "supportsAi", $$v)
                            },
                            expression: "formData.supportsAi",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("支持"),
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("不支持"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "声光报警", prop: "supportsAlarm" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formData.supportsAlarm,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "supportsAlarm", $$v)
                            },
                            expression: "formData.supportsAlarm",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("支持"),
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("不支持"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "夜视模式", prop: "supportsNightVision" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formData.supportsNightVision,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "supportsNightVision", $$v)
                            },
                            expression: "formData.supportsNightVision",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("支持"),
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("不支持"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "云存储", prop: "supportsCloudStorage" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formData.supportsCloudStorage,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData,
                                "supportsCloudStorage",
                                $$v
                              )
                            },
                            expression: "formData.supportsCloudStorage",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("支持"),
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("不支持"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "预置点", prop: "supportsFourPoint" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formData.supportsFourPoint,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "supportsFourPoint", $$v)
                            },
                            expression: "formData.supportsFourPoint",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("支持"),
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("不支持"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Alexa", prop: "supportsAlexa" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formData.supportsAlexa,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "supportsAlexa", $$v)
                            },
                            expression: "formData.supportsAlexa",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("支持"),
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("不支持"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                { attrs: { type: "default" }, on: { click: _vm.close } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }